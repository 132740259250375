<template>
  <div>
    <!-- Business risks table -->
    <div class="card"
         qid="system-business-risk-section"
         id="system_business_risk"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 qid="system-business-risk-table-title" class="mb-2">
              {{ $t('risk_scenarios.business_risks.system_title') }}
            </h5>
            <span class="cursor-pointer task-label-open mr-2"
                  v-if="getTask('update_business_risks')"
                  @click="openToolbar(getTask('update_business_risks'))"
            >
              <font-awesome-icon icon="info-circle" class="text-primary"/>
              {{ $t('tasks.open_label') }}
            </span>
            <span v-if="getGuide('risk_update_systems_business_risks')">
              <GuideButton :guide="getGuide('risk_update_systems_business_risks')"></GuideButton>
            </span>
          </div>
          <div class="col-sm-6">
            <b-form-input
                type="text"
                v-model="keywordValue"
                class="float-right mw-300"
                qid="search-s-input"
                v-on:input="debounceInput"
                :placeholder="$t('risk_scenarios.business_risks.search_placeholder')"
            ></b-form-input>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
            <tr>
              <th scope="col"
                  class="cursor-pointer"
                  @click="selectSort('name')"
                  qid="sort-risk-name"
              >
                {{ $t('risk_scenarios.business_risks.table.name') }}
                <span class="ml-2">
                  <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                  <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                  <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                </span>
              </th>
              <th scope="col" v-if="hasType('business_risk', object)">
                {{ $t('risk_scenarios.business_risks.table.residual_business_risk') }}
              </th>
              <th scope="col" v-if="hasType('business_risk', object)">
                {{ $t('risk_scenarios.business_risks.table.business_risk_response') }}
              </th>
              <th scope="col" v-if="hasType('privacy_risk', object)">
                {{ $t('risk_scenarios.business_risks.table.residual_privacy_risk') }}
              </th>
              <th scope="col" v-if="hasType('privacy_risk', object)">
                {{ $t('risk_scenarios.business_risks.table.privacy_risk_response') }}
              </th>
              <th scope="col" width="180"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in projectTemplateTableItems">
              <td scope="row">
                <router-link
                    :to="{ name: 'systems.view', params: { slug: item.system.slug, client: item.system.active_client.slug }}"
                    qid="s-view-page-link"
                    v-if="item.system.active_client.slug == getClient.slug"
                >
                  {{ item.system.name }}
                </router-link>
                <button v-if="item.system.active_client.slug != getClient.slug"
                        class="btn btn-link btn-sm text-left"
                        @click="openNoticeModal(item.system)"
                >
                  {{ item.system.name }}
                </button>
              </td>
              <td v-if="hasType('business_risk', object)">
                <span v-if="item.residual_business_risk && item.residual_business_risk.tolerance" class="text-normal">
                  <span class="badge-pill badge-success mr-1" v-if="item.residual_business_risk.tolerance == 'Below tolerance'">
                    <font-awesome-icon icon="check" size="xs"/>
                  </span>
                  <span class="badge-pill badge-danger mr-1" v-if="item.residual_business_risk.tolerance == 'Above tolerance'">
                    <font-awesome-icon icon="times" size="xs"/>
                  </span>
                  <span class="mr-1" v-if="item.residual_business_risk.tolerance == 'no risk'">
                      <font-awesome-icon
                          icon="exclamation-triangle"
                          :class="'risk-9'"
                      />
                  </span>
                  {{ $t('risk.' + $options.filters.slugify(item.residual_business_risk.tolerance)) }}
                </span>
                <span v-if="!item.residual_business_risk" class="text-normal">
                  <span>
                    <font-awesome-icon
                        icon="exclamation-triangle"
                        :class="'risk-9'"
                    />
                  </span>
                  {{ $t('risk.no_risk') }}
                </span>
              </td>
              <td v-if="hasType('business_risk', object)">
                <span v-if="item.residual_business_risk && item.residual_business_risk.risk_response">
                  <span class="badge">
                    {{ $t('risk.risk_response.' + $options.filters.slugify(item.residual_business_risk.risk_response)) }}
                  </span>
                </span>
              </td>
              <td v-if="hasType('privacy_risk', object)">
                <span v-if="item.residual_privacy_risk && item.residual_privacy_risk.tolerance" class="text-normal">
                  <span class="badge-pill badge-success mr-1" v-if="item.residual_privacy_risk.tolerance == 'Below tolerance'">
                    <font-awesome-icon icon="check" size="xs"/>
                  </span>
                  <span class="badge-pill badge-danger mr-1" v-if="item.residual_privacy_risk.tolerance == 'Above tolerance'">
                    <font-awesome-icon icon="times" size="xs"/>
                  </span>
                  <span class="mr-1" v-if="item.residual_privacy_risk.tolerance == 'no risk'">
                      <font-awesome-icon
                          icon="exclamation-triangle"
                          :class="'risk-9'"
                      />
                  </span>
                  {{ $t('risk.' + $options.filters.slugify(item.residual_privacy_risk.tolerance)) }}
                </span>
                <span v-if="!item.residual_privacy_risk" class="text-normal">
                  <span>
                    <font-awesome-icon
                        icon="exclamation-triangle"
                        :class="'risk-9'"
                    />
                  </span>
                  {{ $t('risk.no_risk') }}
                </span>
              </td>
              <td v-if="hasType('privacy_risk', object)">
                <span v-if="item.residual_privacy_risk && item.residual_privacy_risk.risk_response">
                  <span class="badge">
                    {{ $t('risk.risk_response.' + $options.filters.slugify(item.residual_privacy_risk.risk_response)) }}
                  </span>
                </span>
              </td>
              <td align="right">
                <button class="btn btn-edit"
                        qid="view-links-button"
                        @click="showCurrentRiskModal(item)"
                >
                  {{ $t('activities.risk_scenario.current_risk_button') }}
                </button>
              </td>
            </tr>
            <tr v-if="!projectTemplatesLoading && !projectTemplateTableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="risk-per-page-options"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>

    <br>


    <ResidualRiskModal
        :object="riskScenarioItem"
        :show="showResidualModal"
        @closeResidualModal="hideCurrentRiskModal"
    ></ResidualRiskModal>

    <PartitionNoticeModal
        :item="selectedItem"
        state="processes.view"
        :show="showNoticeModal"
        @closeViewModal="closeNoticeModal"
    ></PartitionNoticeModal>


  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import PolicyViewModal from '@/views/Processes/PolicyViewModal.vue';
  import MitigatingFactors from '@/views/Activities/MitigatingFactors.vue';
  import ViewDefinitions from '@/views/RiskScenarios/ViewDefinitionsModal.vue';
  import RealtimeRisk from '@/views/RiskScenarios/RealtimeRisk.vue';
  import PartitionNoticeModal from '@/components/PartitionNoticeModal.vue';
  import ResidualRiskModal from '@/views/RiskScenarios/ResidualRiskModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'SystemBusinessRiskTable',
    data: function() {
      return {
        error: '',
        loadingItems: false,
        itemToDelete: {},
        buttonDisabled: false,
        projectTemplateTableItems: [],
        projectTemplatesLoading: true,
        projectTemplateLoading: true,
        showPolicyDescription: false,
        selectedPolicy: [],
        viewPolicyModal: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        keywordValue: '',
        selectedSort: '',
        selectedDirection: '',

        showFullDescription: false,
        showFullRiskResponseDescription: false,

        impactItems: [
          { name: this.$t('impact.very_low'), value: 'Very low' },
          { name: this.$t('impact.low'), value: 'Low' },
          { name: this.$t('impact.high'), value: 'High' },
          { name: this.$t('impact.very_high'), value: 'Very high' }
        ],
        probabilityItems: [
          { name: this.$t('probability.very_unlikely'), value: 'Very unlikely' },
          { name: this.$t('probability.unlikely'), value: 'Unlikely' },
          { name: this.$t('probability.likely'), value: 'Likely' },
          { name: this.$t('probability.very_likely'), value: 'Very likely' }
        ],
        riskResponseItems: [
          { name: this.$t('risk_response.accept'), value: 'Accept' },
          { name: this.$t('risk_response.avoid'), value: 'Avoid' },
          { name: this.$t('risk_response.control'), value: 'Control' },
          { name: this.$t('risk_response.monitor'), value: 'Monitor' },
          { name: this.$t('risk_response.transfer'), value: 'Transfer' },
        ],

        riskResponse: [],
        newRiskResponseDescription: '',

        riskScenarioItem: {},
        privacyRisks: [],
        businessRisks: [],

        newBusinessImpact: [],
        newBusinessProbability: [],

        newBusinessConfidentialityImpact: [],
        newBusinessConfidentialityProbability: [],
        newBusinessIntegrityImpact: [],
        newBusinessIntegrityProbability: [],
        newBusinessAvailabilityImpact: [],
        newBusinessAvailabilityProbability: [],

        newPrivacyConfidentialityImpact: [],
        newPrivacyConfidentialityProbability: [],
        newPrivacyIntegrityImpact: [],
        newPrivacyIntegrityProbability: [],
        newPrivacyAvailabilityImpact: [],
        newPrivacyAvailabilityProbability: [],

        newBusinessDescription: '',
        newPrivacyImpact: [],
        newPrivacyProbability: [],
        newPrivacyDescription: '',
        businessHistoryShow: false,
        privacyHistoryShow: false,
        riskToDelete: {},
        selectedRiskScenario: {},
        riskThreatsItems: [],
        riskVulnerabilitiesItems: [],
        selectedRisk: {},
        dataTypes: [],
        validations: [],
        mitigationFactorsShow: false,
        newBusinessError: '',
        newPrivacyError: '',
        showViewDefinitionsModal: false,
        definitionType: '',
        showNoticeModal: false,
        selectedItem: {},
        showResidualModal: false,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      PolicyViewModal,
      MitigatingFactors,
      ViewDefinitions,
      RealtimeRisk,
      PartitionNoticeModal,
      ResidualRiskModal,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      },
      object: function () {
        this.loadBusinessRisksTable()
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadBusinessRisksTable()
      }, 400),
      hasType(type, object) {
        let self = this;
        let found = false
        if (object) {
          if (object.risk_types && object.risk_types.length) {
            _.find(object.risk_types, function(item) {
              if (item == type) {
                found = true;
              }
            });
          }
        } else {
          if (this.riskScenarioItem && this.riskScenarioItem.risk_scenario) {
            if (this.riskScenarioItem.risk_scenario.risk_types && this.riskScenarioItem.risk_scenario.risk_types.length) {
              _.find(this.riskScenarioItem.risk_scenario.risk_types, function(item) {
                if (item == type) {
                  found = true;
                }
              });
            }
          }
        }
        return found
      },
      pageChanged(page) {
        this.loadBusinessRisksTable(page)
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      openNoticeModal(object) {
        this.selectedItem = object
        this.showNoticeModal = true
      },
      closeNoticeModal() {
        this.selectedItem = {}
        this.showNoticeModal = false
      },
      openViewDefinitionsModal(type) {
        this.definitionType = type
        this.showViewDefinitionsModal = true
      },
      handleCloseViewDefinitionsModal () {
        this.definitionType = ''
        this.showViewDefinitionsModal = false;
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadBusinessRisksTable();
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users && users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load content when page changed listener is triggered
        this.loadBusinessRisksTable(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadBusinessRisksTable(1, self.selectedPerPage)
      },
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Risk scenario' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Risk scenario',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      closeViewModal () {
        this.viewDDModal = false;
        this.selectedDD = []
      },
      openViewModal(object) {
        this.selectedDD = object
        this.viewDDModal = true
      },
      showCurrentRiskModal(object) {
        this.riskScenarioItem = object
        this.riskScenarioItem.risk_category = this.object.risk_category
        this.showResidualModal = true;
      },
      hideCurrentRiskModal() {
        this.riskScenarioItem = {}
        this.showResidualModal = false;
        this.error = ''
        this.loadBusinessRisksTable()
      },
      loadBusinessRisks() {
        let self = this;
        piincHttp.get('systems/' + this.riskScenarioItem.system.slug + '/risk-scenarios/' + this.riskScenarioItem.id + '/risks', { params:
          {
            client: this.getClient.slug,
            type: 'business risk'
          }
        }).then(function(response) {
          self.businessRisks = response.data;
          self.rrLoading = false;
        }, function() {});
      },
      loadPrivacyRisks() {
        let self = this;
        piincHttp.get('systems/' + this.riskScenarioItem.system.slug + '/risk-scenarios/' + this.riskScenarioItem.id + '/risks', { params:
          {
            client: this.getClient.slug,
            type: 'privacy risk'
          }
        }).then(function(response) {
          self.privacyRisks = response.data;
          self.rrLoading = false;
        }, function() {});
      },
      newBusinessFormSubmit() {
        this.newBusinessError = ''
        let formData = new FormData();
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        formData.append("type", "business risk");
        formData.append("description", this.newBusinessDescription);
        formData.append("risk_response_description", this.newRiskResponseDescription);

        if (this.riskResponse && this.riskResponse.value) {
          formData.append("risk_response", this.riskResponse.value);
        }


        if (this.newBusinessImpact && this.newBusinessImpact.value) {
          formData.append("impact", this.newBusinessImpact.value);
        }

        if (this.newBusinessProbability && this.newBusinessProbability.value) {
          formData.append("probability", this.newBusinessProbability.value);
        }

        // Confidentiality
        if (this.newBusinessConfidentialityImpact && this.newBusinessConfidentialityImpact.value) {
          formData.append("confidentiality_impact", this.newBusinessConfidentialityImpact.value);
        }

        if (this.newBusinessConfidentialityProbability && this.newBusinessConfidentialityProbability.value) {
          formData.append("confidentiality_probability", this.newBusinessConfidentialityProbability.value);
        }


        // Integrity
        if (this.newBusinessIntegrityImpact && this.newBusinessIntegrityImpact.value) {
          formData.append("integrity_impact", this.newBusinessIntegrityImpact.value);
        }

        if (this.newBusinessIntegrityProbability && this.newBusinessIntegrityProbability.value) {
          formData.append("integrity_probability", this.newBusinessIntegrityProbability.value);
        }


        // Availability
        if (this.newBusinessAvailabilityImpact && this.newBusinessAvailabilityImpact.value) {
          formData.append("availability_impact", this.newBusinessAvailabilityImpact.value);
        }

        if (this.newBusinessAvailabilityProbability && this.newBusinessAvailabilityProbability.value) {
          formData.append("availability_probability", this.newBusinessAvailabilityProbability.value);
        }

        if (this.getClient.risk_type == "cia_risk") {
          if (
            this.newBusinessConfidentialityImpact && this.newBusinessConfidentialityImpact.value && this.newBusinessConfidentialityProbability && this.newBusinessConfidentialityProbability.value &&
            this.newBusinessIntegrityImpact && this.newBusinessIntegrityImpact.value && this.newBusinessIntegrityProbability && this.newBusinessIntegrityProbability.value &&
            this.newBusinessAvailabilityImpact && this.newBusinessAvailabilityImpact.value && this.newBusinessAvailabilityProbability && this.newBusinessAvailabilityProbability.value
          ) {
            piincHttp.post('/systems/' + this.riskScenarioItem.system.slug + '/risk-scenarios/' + this.riskScenarioItem.id + '/risks', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(response) {
              self.$toastr('success', self.$t('activities.risk_scenario.business_risk.success'))
              self.hideBusinessRiskModal();
              self.loadBusinessRisks();
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
              }
            })
          } else {
            this.newBusinessError += ' ' + this.$t('activities.risk_scenario.business_risk.all_fields_error')
          }

        } else {
          if (this.newBusinessImpact && this.newBusinessImpact.value && this.newBusinessProbability && this.newBusinessProbability.value) {
            piincHttp.post('/systems/' + this.riskScenarioItem.system.slug + '/risk-scenarios/' + this.riskScenarioItem.id + '/risks', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(response) {
              self.$toastr('success', self.$t('activities.risk_scenario.business_risk.success'))
              self.hideBusinessRiskModal();
              self.loadBusinessRisks();
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
              }
            })
          } else {
            if (!this.newBusinessImpact.value) {
              this.newBusinessError += this.$t('activities.risk_scenario.business_risk.impact_error')
            }
            if (!this.newBusinessProbability.value) {
              this.newBusinessError += ' ' + this.$t('activities.risk_scenario.business_risk.probability_error')
            }
          }
        }


      },
      newPrivacyFormSubmit() {
        this.newPrivacyError = ''
        let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        formData.append("type", "privacy risk");
        formData.append("description", this.newPrivacyDescription);
        formData.append("risk_response_description", this.newRiskResponseDescription);

        if (this.riskResponse && this.riskResponse.value) {
          formData.append("risk_response", this.riskResponse.value);
        }

        if (this.newPrivacyImpact && this.newPrivacyImpact.value) {
          formData.append("impact", this.newPrivacyImpact.value);
        }

        if (this.newPrivacyProbability && this.newPrivacyProbability.value) {
          formData.append("probability", this.newPrivacyProbability.value);
        }

        // Confidentiality
        if (this.newPrivacyConfidentialityImpact && this.newPrivacyConfidentialityImpact.value) {
          formData.append("confidentiality_impact", this.newPrivacyConfidentialityImpact.value);
        }

        if (this.newPrivacyConfidentialityProbability && this.newPrivacyConfidentialityProbability.value) {
          formData.append("confidentiality_probability", this.newPrivacyConfidentialityProbability.value);
        }


        // Integrity
        if (this.newPrivacyIntegrityImpact && this.newPrivacyIntegrityImpact.value) {
          formData.append("integrity_impact", this.newPrivacyIntegrityImpact.value);
        }

        if (this.newPrivacyIntegrityProbability && this.newPrivacyIntegrityProbability.value) {
          formData.append("integrity_probability", this.newPrivacyIntegrityProbability.value);
        }


        // Availability
        if (this.newPrivacyAvailabilityImpact && this.newPrivacyAvailabilityImpact.value) {
          formData.append("availability_impact", this.newPrivacyAvailabilityImpact.value);
        }

        if (this.newPrivacyAvailabilityProbability && this.newPrivacyAvailabilityProbability.value) {
          formData.append("availability_probability", this.newPrivacyAvailabilityProbability.value);
        }

        if (this.getClient.risk_type == "cia_risk") {
          if (
            this.newPrivacyConfidentialityImpact && this.newPrivacyConfidentialityImpact.value && this.newPrivacyConfidentialityProbability && this.newPrivacyConfidentialityProbability.value &&
            this.newPrivacyIntegrityImpact && this.newPrivacyIntegrityImpact.value && this.newPrivacyIntegrityProbability && this.newPrivacyIntegrityProbability.value &&
            this.newPrivacyAvailabilityImpact && this.newPrivacyAvailabilityImpact.value && this.newPrivacyAvailabilityImpact && this.newPrivacyAvailabilityImpact.value
          ) {
            piincHttp.post('/systems/' + this.riskScenarioItem.system.slug + '/risk-scenarios/' + this.riskScenarioItem.id + '/risks', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(response) {
              self.$toastr('success', self.$t('activities.risk_scenario.privacy_risk.success'))
              self.hidePrivacyRiskModal();
              self.loadPrivacyRisks();
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
              }
            })
          } else {
            this.newPrivacyError += ' ' + this.$t('activities.risk_scenario.business_risk.all_fields_error')
          }

        } else {
          if (this.newPrivacyImpact && this.newPrivacyImpact.value && this.newPrivacyProbability && this.newPrivacyProbability.value) {
            piincHttp.post('/systems/' + this.riskScenarioItem.system.slug + '/risk-scenarios/' + this.riskScenarioItem.id + '/risks', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(response) {
              self.$toastr('success', self.$t('activities.risk_scenario.privacy_risk.success'))
              self.hidePrivacyRiskModal();
              self.loadPrivacyRisks();
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
              }
            })
          } else {
            if (!this.newPrivacyImpact.value) {
              this.newPrivacyError += this.$t('activities.risk_scenario.privacy_risk.impact_error')
            }
            if (!this.newPrivacyProbability.value) {
              this.newPrivacyError += ' ' + this.$t('activities.risk_scenario.privacy_risk.probability_error')
            }
          }
        }


      },
      showBusinessRiskModal() {
        this.newBusinessError = ''
        this.newBusinessImpact = []
        this.newBusinessProbability = []

        this.newBusinessConfidentialityImpact = []
        this.newBusinessConfidentialityProbability = []
        this.newBusinessIntegrityImpact = []
        this.newBusinessIntegrityProbability = []
        this.newBusinessAvailabilityImpact = []
        this.newBusinessAvailabilityProbability = []

        this.newBusinessDescription = ''
        this.riskResponse = []
        this.newRiskResponseDescription = ''
        this.$refs['new-business-risk'].show()
      },
      hideBusinessRiskModal() {
        this.$refs['new-business-risk'].hide()
      },
      showPrivacyRiskModal() {
        this.newPrivacyError = ''
        this.newPrivacyImpact = []
        this.newPrivacyProbability = []

        this.newPrivacyConfidentialityImpact = []
        this.newPrivacyConfidentialityProbability = []
        this.newPrivacyIntegrityImpact = []
        this.newPrivacyIntegrityProbability = []
        this.newPrivacyAvailabilityImpact = []
        this.newPrivacyAvailabilityProbability = []

        this.newPrivacyDescription = ''
        this.riskResponse = []
        this.newRiskResponseDescription = ''
        this.$refs['new-privacy-risk'].show()
      },
      hidePrivacyRiskModal() {
        this.$refs['new-privacy-risk'].hide()
      },
      loadRiskVulnerabilities(reset) {
        if (reset) {
          this.page = 1
          this.showMore = true;
          this.riskVulnerabilitiesItems = []
        }
        let self = this;
        piincHttp.get('risk-scenarios/' + this.selectedRiskScenario.risk_scenario.slug + '/vulnerabilities', { params:
          {
            client: this.getClient.slug,
            page: this.page
          }
        }).then(function(response) {
          self.page += 1;
          self.loading = false;
          if (response.data.items && response.data.items.length < 10) {
            self.showMore = false;
          }

          self.riskVulnerabilitiesItems.push(...response.data.items)

        }, function() {});
      },
      loadRiskThreats(reset) {
        if (reset) {
          this.page = 1
          this.showMore = true;
          this.riskThreatsItems = []
        }
        let self = this;
        piincHttp.get('risk-scenarios/' + this.selectedRiskScenario.risk_scenario.slug + '/threats', { params:
          {
            client: this.getClient.slug,
            page: this.page
          }
        }).then(function(response) {
          self.page += 1;
          self.loading = false;
          if (response.data.items && response.data.items.length < 10) {
            self.showMore = false;
          }

          self.riskThreatsItems.push(...response.data.items)

        }, function() {});
      },
      showViewRiskModal(object) {
        this.selectedRisk = object;
        this.$refs['view-risk'].show()
      },
      hideViewRiskModal() {
        this.selectedRisk = {}
        this.$refs['view-risk'].hide()
      },
      showActivityRiskScenarioDeleteModal(object) {
        this.riskToDelete = object
        this.$refs['delete-activity-risk'].show()
      },
      hideActivityRiskScenarioDeleteModal() {
        this.riskToDelete = {}
        this.$refs['delete-activity-risk'].hide()
      },
      confirmDeleteActivityRisk(object) {
        let self = this;
        piincHttp.delete('systems/' + this.riskScenarioItem.system.slug + '/risk-scenarios/' + this.riskScenarioItem.id + '/risks/' + object.id).then(function() {
          self.hideActivityRiskScenarioDeleteModal();
          self.loadBusinessRisks();
          self.loadPrivacyRisks();
          // Trigger toastr message after modal close
          if (object.type == 'business risk') {
            self.$toastr('success', self.$t('activities.risk_scenario.delete.business_success'))
          } else {
            self.$toastr('success', self.$t('activities.risk_scenario.delete.privacy_success'))
          }
        }, function() {});
      },
      loadBusinessRisksTable(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('risk-scenarios/' + this.$route.params.slug + '/business-risks', { params:
          {
            type: "system",
            page: pageNumber,
            per_page: perPageNumber,
            sort: this.selectedSort !== '' ? this.selectedSort : undefined,
            order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
            client: this.getClient.slug,
            keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
          }
        }).then(function(response) {
          self.projectTemplateTableItems = response.data.items;
          self.projectTemplatesLoading = false;
          self.totalRows = response.data.total_count;
        }, function() {});
      }
    },
    created () {
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadBusinessRisksTable();
      this.loadGuides('risk_update_systems_business_risks')
    }
  }
</script>
