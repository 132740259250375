<template>
  <div>
    <!-- System table -->
    <div class="card pagination-margin" qid="activities-section">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="float-left">{{ $t('processes.assessments.title') }}</h5>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('projects.table.name') }}
            </th>
            <th scope="col">
              {{ $t('projects.table.type') }}
            </th>
            <th scope="col" width="200">
              {{ $t('system.last_updated') }}
            </th>
            <th scope="col" width="80"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(object, index) in tableItems" :key="index">
            <td>
              <router-link
                :to="{ name: 'projects.view', params: { slug: object.slug }}"
                qid="s-view-page-link"
              >
                {{ object.title }}
              </router-link>
            </td>
            <td>
              <span class="badge mb-1" :title="object.assessment.name">
                {{ object.assessment.name | truncate(35) }}
              </span>
            </td>
            <td>
              <span class="text-normal">
                {{ object.updated_at | formatDate }}
              </span>
            </td>
            <td align="right">
              <AssessmentViewSingleTaskModal
                  :project="object"
                  :object="process"
                  v-if="isGranted([permissions.PROCESS_VIEW, permissions.PROCESS_VIEW_OWN], process)"
                  @reloadContent="loadContent"
              ></AssessmentViewSingleTaskModal>
            </td>
          </tr>
          <tr v-if="!sLoading && !tableItems.length" qid="no-results-row" class="text-center">
            <td colspan="10">
              <h5 class="mt-3">
                {{ $t('system.no_results') }}
              </h5>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>

    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

    <PartitionNoticeModal
        :item="selectedItem"
        state="activities.view"
        :show="showNoticeModal"
        @closeViewModal="closeNoticeModal"
    ></PartitionNoticeModal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import PartitionNoticeModal from '@/components/PartitionNoticeModal.vue';
  import AssessmentViewSingleTaskModal from '@/views/Tasks/AssessmentViewSingleTaskModal.vue';

  export default {
    name: 'AssessmentsTable',
    data: function() {
      return {

        // Systems
        tableItems: [],
        selectedS: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        pItems: [],
        sLoading: true,

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        selectedP: [],
        showSDescription: false,
        selectedItem: {},
        showNoticeModal: false,
        showTagsModal: false,
        showUsersModal: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
      }
    },
    props: {
      process: {
        type: Object,
        required: true
      }
    },
    components: {
      ShowMoreModal,
      PartitionNoticeModal,
      AssessmentViewSingleTaskModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadContent()
      }
    },
    methods: {
      pageChanged(page) {
        this.loadContent(page)
      },
      perPageChanged(perPage) {
        let self = this;
        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load content when page changed listener is triggered
        this.loadContent(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadContent(1, self.selectedPerPage)
      },
      openViewSModal(object) {
        this.selectedS = object
        this.viewSModal = true
      },
      handleCloseViewSModal () {
        this.selectedS = []
        this.viewSModal = false;
      },
      openNoticeModal(object) {
        this.selectedItem = object
        this.showNoticeModal = true
      },
      closeNoticeModal() {
        this.selectedItem = {}
        this.showNoticeModal = false
      },
      openTagsModal(object) {
        this.selectedItem = object
        this.showTagsModal = true
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 3) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 3);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      loadContent(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('processes/' + this.$route.params.slug + '/assessments', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.sLoading = false;
          self.tableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;
        }, function() {});
      },
    },
    created () {
      // Table items
      this.loadContent();
    }
  }
</script>
