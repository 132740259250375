<template>
  <div>

    <!-- View third party modal template -->
    <b-modal ref="view-tp"
             hide-footer
             @hide="hideTPModal"
    >
      <div class="d-block text-center">
        <h4 qid="view-tp-title" v-if="object">
          {{ object.name }}
        </h4>
      </div>

      <div v-if="object">
        <br>
        <div v-if="object.description">
          <label>{{ $t('maintenance.third_parties.create.description_label') }}</label>
          <p class="text-formatted">{{ object.description }}</p>
          <br>
        </div>
      </div>

      <div v-if="object && object.actors && object.actors.length">
        <label class="mt-3">
          {{ $t('maintenance.threats.table.actors') }}
        </label>
        <br>
        <span class="mr-1" v-for="actor in object.actors" qid="p-tags-list">
              <span class="badge badge-info mb-1">{{actor.name}}</span>
            </span>
      </div>

      <b-button class="float-right"
                variant="success"
                @click="hideTPModal"
                qid="create-p-cancel-button"
      >
        {{ $t('processes.policies.view.close') }}
      </b-button>
      <br>
  </b-modal>

</div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'RiskScenarioThreatViewModal',
    data: function() {
      return {
        error: '',
        showInput: false,
        inputLink: '',
        loadingItems: false,
        showArticleDescription: false,
        secondaryDO: [],
        selectedFileObject: {},
        selectedFileIndex: '',
        selectedLinkObject: {},
        selectedLinkIndex: '',
      }
    },
    props: {
      object: {
        type: [Object,Array]
      },
      showModal: {
        type: Boolean
      },
      active: {
        type: Boolean
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when prop changes
      showModal: function (value) {
        if (value) {
          this.showTPModal()
        }
      }
    },
    methods: {
      showTPModal() {
        this.$refs['view-tp'].show()
      },
      hideTPModal() {
        this.error = ''
        this.showArticleDescription = false;
        this.showInput = false;
        this.$emit('closeViewModal');
        this.$refs['view-tp'].hide()
      }
    },
    components: {

    },
    created () {

    }
  }
</script>
