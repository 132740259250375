<template>
  <div>
    <!-- Retention rules table -->
    <div class="card pagination-margin"
         v-if="isGranted([permissions.ACTIVITY_RETENTION_RULE_VIEW, permissions.ACTIVITY_RETENTION_RULE_VIEW_OWN], activity)"
         qid="vulnerabilities-section"
         id="update_vulnerability"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="float-left">
              <h5 class="mb-2" qid="vulnerabilities-table-title">
                {{ $t('risk_scenarios.tab.vulnerabilities') }}
              </h5>
              <span class="cursor-pointer task-label-open mr-2"
                    v-if="getTask('update_vulnerability')"
                    @click="openToolbar(getTask('update_vulnerability'))"
              >
                <font-awesome-icon icon="info-circle" class="text-primary"/>
                {{ $t('tasks.open_label') }}
              </span>
              <span v-if="getGuide('risk_update_vulnerability')">
                <GuideButton :guide="getGuide('risk_update_vulnerability')"></GuideButton>
              </span>
            </div>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success float-right"
                    @click="showCreateRRModal()"
                    qid="retention-rule-create-button"
                    v-if="isGranted([permissions.ACTIVITY_RETENTION_RULE_CREATE, permissions.ACTIVITY_RETENTION_RULE_CREATE_OWN], activity) && (!getClient.parent || (getClient.parent && activity.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('risk_scenarios.vulnerability.add_new') }}
            </button>

            <b-form-input
                type="text"
                v-model="keywordValue"
                class="float-right mw-300 mr-3"
                qid="search-s-input"
                v-on:input="debounceInput"
                :placeholder="$t('risk_scenarios.vulnerability.search_placeholder')"
            ></b-form-input>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('name')"
                qid="sort-s-name"
            >
              {{ $t('risk_scenarios.vulnerability.table.name') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
              </span>
            </th>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('updated_at')"
                qid="sort-s-name"
            >
              {{ $t('system.last_updated') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
              </span>
            </th>
            <th width="130"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in rrTableItems">
              <td>
                <button v-if="object.vulnerability && object.vulnerability.name"
                        @click="openViewVulnerabilityModal(object.vulnerability)"
                        class="btn btn-link btn-sm text-left"
                >
                  {{ object.vulnerability.name }}
                </button>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
              <td class="text-right">
                <button v-if="object.vulnerability && object.vulnerability.name"
                        @click="openViewVulnerabilityModal(object.vulnerability)"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>

                <button class="btn btn-delete"
                      qid="delete-vulnerability-modal"
                      @click="showRRConfirmDeleteModal(object)"
                      v-if="isGranted([permissions.ACTIVITY_RETENTION_RULE_DELETE, permissions.ACTIVITY_RETENTION_RULE_DELETE_OWN], activity) && (!getClient.parent || (getClient.parent && activity.partition))"
                >
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </td>
            </tr>
            <tr v-if="!rrLoading && !rrTableItems.length" qid="no-results-row-rr" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    label="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>


    <!-- Delete vulnerability modal template -->
    <b-modal ref="delete-vulnerability" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-vulnerability-title">
          {{ $t('risk_scenarios.vulnerability.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('risk_scenarios.vulnerability.delete.description') }}
        <span v-if="itemToDelete.vulnerability">{{ itemToDelete.vulnerability.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('risk_scenarios.vulnerability.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideRRConfirmDeleteModal"
                  qid="delete-vulnerability-cancel-button"
        >
          {{ $t('risk_scenarios.vulnerability.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteRR(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-vulnerability-submit-button"
        >
          {{ $t('risk_scenarios.vulnerability.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new vulnerability -->
    <b-modal ref="add-vulnerability" hide-footer @hide="hideCreateRRModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{ $t('risk_scenarios.vulnerability.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="acFormSubmit" qid="create-ac-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-ac-pg-label">
              {{ $t('risk_scenarios.vulnerability.create.rr_label') }}
            </label>
            <multiselect
                v-model="selectedRR"
                :options="rrItems"
                :placeholder="$t('risk_scenarios.vulnerability.create.rr_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadVulnerabilities"
                track-by="slug"
                qid="create-rr-form-rr"
            ></multiselect>
          </b-form-group>
          <div v-if="selectedRR && selectedRR.description"
               class="text-center mb-3 mt-4"
          >
            <span class="cursor-pointer">
              <span class="mr-2"
                    @click="showRRDescription = !showRRDescription"
                    qid="expand-rr-description"
              >
                {{$t('risk_scenarios.vulnerability.create.rr_description_expand')}}
              </span>
              <font-awesome-icon icon="chevron-down" v-if="!showRRDescription"/>
              <font-awesome-icon icon="chevron-up" v-if="showRRDescription"/>
            </span>
          </div>
          <div v-if="selectedRR && selectedRR.description && showRRDescription" class="mb-4 mt-2">
            <span class="text-formatted">
              {{ selectedRR.description }}
            </span>
          </div>
          <div v-if="selectedRR && selectedRR.time_limit">
            <label class="mt-3">
                {{ $t('risk_scenarios.vulnerability.create.time_limit_label') }}
            </label>
            <br>
            <span v-if="selectedRR">
              {{ selectedRR.time_limit }}
            </span>
          </div>
          <br>
          <b-form-group v-if="selectedRR && selectedRR.media && selectedRR.media.length">
            <label qid="create-rr-attachments-label">
              {{ $t('maintenance.third_parties.create.attachments') }}
            </label>
            <br>
            <p v-for="file in selectedRR.media" :key="file.file_name">
              <span class="mr-3 float-right text-muted cursor-pointer"
                    @click="downloadMedia(file)"
                    qid="download-media"
              >
                <font-awesome-icon icon="cloud-download-alt" />
              </span>
              <a @click="downloadMedia(file)" qid="create-rr-media-item">
                {{ file.file_name }}
              </a>
            </p>
          </b-form-group>
          <b-form-group v-if="selectedRR && selectedRR.links && selectedRR.links.length">
            <label qid="create-rr-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>
            <p v-for="(link, index) in selectedRR.links">
              <a :href="link.url" target="_blank" qid="create-rr-link-item">
                {{ link.name ? link.name : link.url }}
              </a>
            </p>

          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreateRRModal"
                    qid="create-rr-cancel-button"
          >
            {{ $t('risk_scenarios.vulnerability.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-rr-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('risk_scenarios.vulnerability.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <RiskScenarioVulnerabilityViewModal
            :object="selectedItem"
            :showModal="viewVulnerabilityModal"
            @closeViewModal="handleCloseViewThreatModal"
            v-if="selectedItem"
    ></RiskScenarioVulnerabilityViewModal>
  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import ValidationList from '@/views/Validations/ValidationList.vue';
  import ValidationForm from '@/views/Validations/ValidationForm.vue';
  import RiskScenarioVulnerabilityViewModal from '@/views/RiskScenarios/RiskScenarioVulnerabilityViewModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'VulnerabilitiesTable',
    data: function() {
      return {
        acForm: {
          name: ''
        },
        error: '',
        loadingItems: false,
        selectedRR: [],
        viewRRModal: false,
        editRRModal: false,
        selectedRRSort: '',
        selectedRRDirection: '',
        acValidationShow: false,
        rrTableItems: [],
        rrLoading: true,
        rrItems: [],
        updateValidationList: false,
        itemToDelete: {},
        itemObject: {},
        reCertificationOptions: [],
        provisioningOptions: [],
        selectedRC: [],
        selectedP: [],
        validationOptions: [],
        validation: undefined,
        disabledValidationOptions: [],
        showRRDescription: false,
        buttonDisabled: false,

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',
        selectedItem: {},
        viewVulnerabilityModal: false,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      ValidationList,
      ValidationForm,
      RiskScenarioVulnerabilityViewModal,
      GuideButton
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadVulnerabilities();
      },
      object: function (value) {
        this.activity = value
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadRiskScenarioVulnerabilities()
      }, 400),
      setValidation(object) {
        this.validation = object
        this.updateValidationList = false;
      },
      openViewVulnerabilityModal(object) {
        this.selectedItem = object
        this.viewVulnerabilityModal = true
      },
      handleCloseViewThreatModal () {
        this.selectedItem = []
        this.viewVulnerabilityModal = false;
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadRiskScenarioVulnerabilities();
      },
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      downloadMedia(object){
        piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Risk scenario' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Risk scenario',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      showCreateRRModal() {
        this.$refs['add-vulnerability'].show()
      },
      hideCreateRRModal() {
        this.error = ''
        this.selectedRR = []
        this.loadContent()
        this.$refs['add-vulnerability'].hide()
      },
      showRRConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-vulnerability'].show()
      },
      hideRRConfirmDeleteModal() {
        this.itemToDelete = {}
        this.loadContent()
        this.$refs['delete-vulnerability'].hide()
      },
      handleValidationModalClose() {
        this.updateValidationList = true
        this.loadContent()
      },
      confirmDeleteRR(object) {
        let self = this;
        piincHttp.delete('risk-scenarios/' + this.$route.params.slug + '/vulnerabilities/' + object.id).then(function() {
          self.hideRRConfirmDeleteModal();
          self.loadRiskScenarioVulnerabilities();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('risk_scenarios.vulnerability.delete.success'))
        }, function() {});
      },
      acFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedRR && this.selectedRR.id) {
          formData.append("vulnerability_id", this.selectedRR.id);
        }

        piincHttp.post('/risk-scenarios/' + this.$route.params.slug + '/vulnerabilities', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('risk_scenarios.vulnerability.create.success'))
          self.hideCreateRRModal();
          self.loadRiskScenarioVulnerabilities();
          self.$emit('updated');
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.vulnerability_id) {
              self.error += ' ' + error.data.errors.vulnerability_id[0]
            }
          }
        })
      },
      loadContent() {
        let self = this;
        piincHttp.get('risk-scenarios/' + this.$route.params.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.activity = response.data;
        }, function() {});
      },
      loadRiskScenarioVulnerabilities(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('risk-scenarios/' + this.$route.params.slug + '/vulnerabilities', { params:
            {
              page: pageNumber,
              per_page: perPageNumber,
              sort: this.selectedSort !== '' ? this.selectedSort : undefined,
              order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
              keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
              client: this.getClient.slug
            }
        }).then(function(response) {

          self.rrLoading = false;
          self.rrTableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;
        }, function() {});
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadRiskScenarioVulnerabilities(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadRiskScenarioVulnerabilities(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadRiskScenarioVulnerabilities(1, self.selectedPerPage)

      },
      loadVulnerabilities(query) {
        let self = this;
        piincHttp.get('vulnerabilities', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.rrItems = response.data.items
          if (self.rrItems && !self.rrItems.length && !query) {
            self.rrItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
          if (self.rrItems && self.rrItems.length) {
            self.validationOptions = _.cloneDeep(self.rrItems[0].validationOptions)
          }
        }, function() {});
      },
    },
    created () {
      this.activity = this.object
      if (this.getClient && this.getClient.slug) {
        this.loadVulnerabilities();
      }
      // Table items
      this.loadRiskScenarioVulnerabilities();
      this.loadGuides('risk_update_vulnerability')
    }
  }
</script>
