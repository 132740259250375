<template>
  <div>

    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-7">
              <h4 qid="p-view-page-title" class="mb-3">
                {{ riskScenario.name }}
              </h4>
              <div class="mb-2">
                <span class="cursor-pointer task-label-open mr-2"
                      v-if="getTask('update_basic_info')"
                      @click="openToolbar(getTask('update_basic_info'))"
                >
                  <font-awesome-icon icon="info-circle" class="text-primary"/>
                  {{ $t('tasks.open_label') }}
                </span>
                <span v-if="getGuide('risk_update_basic_info')">
                  <GuideButton :guide="getGuide('risk_update_basic_info')"></GuideButton>
                </span>
              </div>
              <span v-if="riskScenario.users && riskScenario.users.length" class="mr-2">
                {{ $t('table.owners') }}:
              </span>
              <span class="mr-1" v-for="user in riskScenario.users" qid="riskScenario-users-list">
                <Avatar :object="user" size="xs"></Avatar>
              </span>
              <span v-if="riskScenario.tags_translated && riskScenario.tags_translated.length" class="mr-2 ml-2">
                {{ $t('table.tags') }}:
              </span>
              <span v-if="riskScenario.tags_translated && riskScenario.tags_translated.length" qid="activity-tags-list">
                <span class="badge" v-for="tag in getReducedTags(riskScenario.tags_translated)" :title="tag.name_translated">
                  <font-awesome-icon icon="tag"/>
                  {{ tag.name_translated | truncate(35) }}
                </span>
                <span v-if="riskScenario.tags_translated.length > 3"
                      class="badge cursor-pointer"
                      @click="openTagsModal(riskScenario)"
                >
                  <font-awesome-icon icon="tags"/>
                  + {{ riskScenario.tags_translated.length - 3 }}
                </span>
              </span>
              <span v-if="riskScenario.risk_category" class="mr-2 ml-2">
                {{ $t('risk_scenarios.table.category') }}:
              </span>
              <span v-if="riskScenario.risk_category" class="badge">
                <font-awesome-icon icon="virus"/>
                {{riskScenario.risk_category.name}}
              </span>
              <span v-if="riskScenario.types && riskScenario.types.length" class="mr-2 ml-2">
                {{ $t('table.scope') }}:
              </span>
              <span class="mr-1" qid="riskScenario-tags-list" v-if="riskScenario.types && riskScenario.types.length">
                <span class="mr-1" v-for="type in riskScenario.types" qid="p-tags-list">
                  <span class="badge">
                    <font-awesome-icon icon="crosshairs"/>
                    {{ $t('risk_scenarios.type.' + $options.filters.slugify(type)) }}
                  </span>
                </span>
              </span>
              <span v-if="riskScenario.types && riskScenario.types.length" class="mr-2 ml-2">
                {{ $t('risk_scenarios.create.risk_type_label') }}:
              </span>
              <span class="mr-1" qid="riskScenario-tags-list" v-if="riskScenario.risk_types && riskScenario.risk_types.length">
                <span class="mr-1" v-for="type in riskScenario.risk_types" qid="p-tags-list">
                  <span class="badge">
                    <font-awesome-icon icon="theater-masks"/>
                    {{ $t('risk_scenarios.risk_type.' + $options.filters.slugify(type)) }}
                  </span>
                </span>
              </span>
            </div>
            <div class="col-md-5 text-right" qid="view-s-modified">
              <div class="mb-4">
                <span class="h6 ml-3">
                  <RiskScenarioEditForm
                      :object="riskScenario"
                      :edit="edit"
                      @reloadContent="reloadContent"
                      v-if="isGranted([permissions.RISK_SCENARIO_EDIT, permissions.RISK_SCENARIO_EDIT_OWN], riskScenario) && (!getClient.parent || (getClient.parent && riskScenario.partition))"
                  ></RiskScenarioEditForm>
                  <span class="btn btn-delete"
                        qid="delete-s-button"
                        @click="showConfirmDeleteModal(riskScenario)"
                        v-if="isGranted([permissions.RISK_SCENARIO_DELETE, permissions.RISK_SCENARIO_DELETE_OWN], riskScenario) && !riskScenario.hasRunningProjects && (!getClient.parent || (getClient.parent && riskScenario.partition))"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                </span>
              </div>
              <div>
                <span>{{ $t('table.last_updated') }}</span>
                    <span class="ml-2 text-muted small" qid="view-s-modified-date">
                  {{ riskScenario.updated_at | formatDate }}
                </span>
                <Avatar v-if="riskScenario.updated_by"
                        :object="riskScenario.updated_by"
                        size="xs"
                        class="ml-3 mr-2"
                ></Avatar>
                <span v-if="riskScenario.updated_by" qid="view-s-modified-by">
                  {{ riskScenario.updated_by.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-tabs class="mt-3" style="margin-bottom:30px;" v-model="tabIndex">

        <!-- Basic info tab -->
        <b-tab :title="$t('projects.tab.basic_info')" active @click="basicTabClicked">
          <br>
          <div class="card" qid="basic-info-section">
            <div class="card-body">
              <div class="content-view-body">

                <div>
                  <div class="mb-2">
                    <h5 class="h5">
                      <span class="float-right btn-sm btn-edit"
                            @click="showDescriptionInputFunction"
                            qid="riskScenario-edit-description"
                            v-if="!showDescriptionInput && isGranted([permissions.RISK_SCENARIO_EDIT, permissions.RISK_SCENARIO_EDIT_OWN], riskScenario) && (!getClient.parent || (getClient.parent && riskScenario.partition))"
                      >
                        <font-awesome-icon icon="pen"/>
                      </span>
                      <span class="float-right" v-if="showDescriptionInput">
                        <div class="text-right">
                          <b-button class="btn-sm inline-edit-button"
                                    variant="secondary"
                                    @click="cancelDescriptionForm"
                                    qid="riskScenario-edit-button-cancel"
                          >
                            {{ $t('activities.create.cancel') }}
                          </b-button>
                          <b-button class="ml-3 btn-sm inline-edit-button"
                                    variant="success"
                                    @click="formDescriptionSubmit"
                                    qid="riskScenario-edit-button-submit"
                          >
                            {{ $t('activities.create.submit') }}
                          </b-button>
                        </div>
                      </span>
                      {{ $t('activities.description_label') }}
                    </h5>

                    <span v-if="getGuide('risk_update_description')">
                      <GuideButton :guide="getGuide('risk_update_description')"></GuideButton>
                    </span>
                  </div>

                  <div v-if="showDescriptionInput">
                    <b-form-textarea
                      qid="edit-tp-form-description"
                      v-model="riskScenarioEditDescription"
                      rows="6"
                      :placeholder="$t('maintenance.third_parties.create.description_placeholder')"
                    ></b-form-textarea>
                  </div>

                  <div v-if="!showDescriptionInput" class="section-container">
                    <div v-if="riskScenario.description && riskScenario.description.length > descriptionLength">
                      <p v-if="showFullDescription" class="text-formatted">{{ riskScenario.description }}</p>
                      <p v-if="!showFullDescription" class="text-formatted">{{ riskScenario.description | truncate(descriptionLength) }}</p>
                      <div class="text-center mt-3" @click="showFullDescription=!showFullDescription">
                        <span class="cursor-pointer" v-if="!showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                                <span class="cursor-pointer" v-if="showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p v-if="riskScenario.description && riskScenario.description.length <= descriptionLength"
                       class="text-formatted"
                    >{{ riskScenario.description }}</p>
                    <p v-if="!riskScenario.description" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>

        </b-tab>

        <!-- Threats tab -->
        <b-tab :title="$t('risk_scenarios.tab.threats')" class="mt-3 threats-tab" @click="threatsTabClicked">
          <div v-if="showThreatsTab">
            <ThreatsTable :object="riskScenario" @updated="updateRiskScenario"></ThreatsTable>
          </div>
        </b-tab>

        <!-- Vulnerabilities tab -->
        <b-tab :title="$t('risk_scenarios.tab.vulnerabilities')" class="mt-3 vulnerabilities-tab" @click="vulnerabilitiesTabClicked">
          <div v-if="showVulnerabilitiesTab">
            <VulnerabilitiesTable :object="riskScenario" @updated="updateRiskScenario"></VulnerabilitiesTable>
          </div>
        </b-tab>

        <!-- Business risks tab -->
        <b-tab :title="$t('risk_scenarios.tab.business_risks')" class="mt-3 business-risks-tab" @click="businessRisksTabClicked">
          <div v-if="showBusinessRisksTab">
            <ProcessBusinessRisksTable :object="riskScenario" @updated="updateRiskScenario" v-if="hasContentType('process')"></ProcessBusinessRisksTable>
            <ActivityBusinessRisksTable :object="riskScenario" @updated="updateRiskScenario" v-if="hasContentType('activity')"></ActivityBusinessRisksTable>
            <SystemBusinessRisksTable :object="riskScenario" @updated="updateRiskScenario" v-if="hasContentType('system')"></SystemBusinessRisksTable>
            <ThirdPartyBusinessRisksTable :object="riskScenario" @updated="updateRiskScenario" v-if="hasContentType('third_party')"></ThirdPartyBusinessRisksTable>
          </div>
        </b-tab>

        <!-- Validations tab -->
        <b-tab :title="$t('risk_scenarios.tab.validation')"
               class="mt-3"
               @click="validationTabClicked"
        >
          <!-- Validation section component -->
          <Validations
              :object="riskScenario"
              type="risk_scenario"
              :checklist="riskScenario.validationOptions"
              @updated="updateValidation"
              :permission="['RISK_SCENARIO_VALIDATE', 'RISK_SCENARIO_VALIDATE_OWN']"
              createButton="secondary"
              activeGuide="risk_update_validation"
          ></Validations>
        </b-tab>

        <!-- Risk scenario history tab -->
        <b-tab :title="$t('risk_scenarios.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <RiskScenarioHistory :object="riskScenario"></RiskScenarioHistory>
          </div>
        </b-tab>

      </b-tabs>


      <!-- Delete Risk scenario template -->
      <b-modal ref="delete-riskScenario" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-s-title">
            {{ $t('risk_scenarios.delete.title') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('risk_scenarios.delete.description') }} {{ itemToDelete.name }}
        </h5>
        <h5 class="text-center">
          {{ $t('risk_scenarios.delete.are_you_sure') }}
        </h5>
        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideConfirmDeleteModal"
                    qid="delete-s-cancel-button"
          >
            {{ $t('risk_scenarios.delete.cancel') }}
          </b-button>
          <b-button @click="confirmDelete(itemToDelete)"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-s-submit-button"
                    v-if="isGranted([permissions.RISK_SCENARIO_DELETE, permissions.RISK_SCENARIO_DELETE_OWN], riskScenario)"
          >
            {{ $t('risk_scenarios.delete.submit') }}
          </b-button>
        </div>
      </b-modal>

      <ConfirmRemoveAttachment
          :object="selectedFile"
          :show="showDeleteAttachmentModal"
          type="file"
          @closeConfirmModal="closeDeleteAttachmentModal"
      ></ConfirmRemoveAttachment>

      <ConfirmRemoveAttachment
          :object="selectedLink"
          :show="showDeleteLinkModal"
          type="link"
          @closeConfirmModal="closeDeleteLinkModal"
      ></ConfirmRemoveAttachment>

      <ShowMoreModal
          :items="selectedItem.tags_translated"
          :show="showTagsModal"
          @closeViewModal="closeTagsModal"
          type="tags"
      ></ShowMoreModal>
    </div>


    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import RiskScenarioEditForm from '@/views/RiskScenarios/RiskScenarioEditForm.vue';
import Avatar from '@/components/Avatar.vue';
import RiskScenarioHistory from '@/views/RiskScenarios/RiskScenarioHistory.vue';
import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
import Validations from '@/views/Validations/Validations.vue';
import ThreatsTable from '@/views/RiskScenarios/ThreatsTable.vue';
import VulnerabilitiesTable from '@/views/RiskScenarios/VulnerabilitiesTable.vue';
import ActivityBusinessRisksTable from '@/views/RiskScenarios/ActivityBusinessRisksTable.vue';
import ProcessBusinessRisksTable from '@/views/RiskScenarios/ProcessBusinessRisksTable.vue';
import SystemBusinessRisksTable from '@/views/RiskScenarios/SystemBusinessRisksTable.vue';
import ThirdPartyBusinessRisksTable from '@/views/RiskScenarios/ThirdPartyBusinessRisksTable.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import GuideButton from '@/components/guide/GuideButton.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';


export default {
  name: 'ProjectTemplateView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('risk_scenarios.title'),
          to: '/risk-scenarios'
        }
      ],
      descriptionLength: config.descriptionLength,
      showFullDescription: false,
      riskScenario: {},
      edit: this.$route.params.edit,
      selectedSort: '',
      selectedDirection: '',
      lbTableItems: [],
      lbLoading: true,
      showInput: false,

      itemToDelete: {},
      users: [],
      error: '',
      lbForm: {
        name: '',
        article_reason: '',
        links: []
      },
      inputLink: '',
      selectedFiles: [],
      selectedFile: {},
      selectedLink: {},
      showDeleteAttachmentModal: false,
      showDeleteLinkModal: false,
      showDescriptionInput: false,
      riskScenarioEditDescription: '',

      // Tabs
      showValidationTab: false,
      showThreatsTab: false,
      showVulnerabilitiesTab: false,
      showBusinessRisksTab: false,
      showHistoryTab: false,
      showBasicInfoTab: true,

      buttonDisabled: false,
      tabIndex: 0,
      myGuides: [],
      selectedItem: {},
      showTagsModal: false,
      show404: false,
      show403: false,
    }
  },
  computed: {
    getClient() {
      return this.$store.state.client
    },
    getFiles() {
      return this.selectedFiles
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        this.goToList()
      } else {
        this.loadContent(true);
      }
    }
  },
  components: {
    RiskScenarioEditForm,
    Avatar,
    RiskScenarioHistory,
    ConfirmRemoveAttachment,
    Validations,
    ThreatsTable,
    VulnerabilitiesTable,
    ActivityBusinessRisksTable,
    ProcessBusinessRisksTable,
    SystemBusinessRisksTable,
    ThirdPartyBusinessRisksTable,
    ShowMoreModal,
    GuideButton,
    page403,
    page404
  },
  methods: {
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    goToList() {
      this.$router.push('/risk-scenarios')
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    hasContentType(type) {
      let existingType = _.find(this.riskScenario.types, function(object) {
        return object == type
      });
      return existingType
    },
    resetFile() {
      let imagefile = document.querySelector('#file');
      imagefile.value = '';
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('risk-scenarios/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug,
        }
      }).then(function(response) {
        self.riskScenario = response.data;
        if (breadcrumbPush) {
          // Push Third party name in breadcrumb array
          self.breadcrumbItems.push({text: self.riskScenario.name, active: true});
        }
      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    updateRiskScenario() {
      this.loadContent();
    },
    historyTabClicked() {
      this.showHistoryTab = true;
      this.showThreatsTab = false;
      this.showVulnerabilitiesTab = false;
      this.showBusinessRisksTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
    },
    basicTabClicked() {
      this.showHistoryTab = false;
      this.showThreatsTab = false;
      this.showVulnerabilitiesTab = false;
      this.showBusinessRisksTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = true;
    },
    threatsTabClicked() {
      this.showHistoryTab = false;
      this.showThreatsTab = true;
      this.showVulnerabilitiesTab = false;
      this.showBusinessRisksTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
    },
    vulnerabilitiesTabClicked() {
      this.showHistoryTab = false;
      this.showThreatsTab = false;
      this.showVulnerabilitiesTab = true;
      this.showBusinessRisksTab = false;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
    },
    businessRisksTabClicked() {
      this.showHistoryTab = false;
      this.showThreatsTab = false;
      this.showVulnerabilitiesTab = false;
      this.showBusinessRisksTab = true;
      this.showValidationTab = false;
      this.showBasicInfoTab = false;
    },
    validationTabClicked() {
      this.showHistoryTab = false;
      this.showThreatsTab = false;
      this.showVulnerabilitiesTab = false;
      this.showBusinessRisksTab = false;
      this.showValidationTab = true;
      this.showBasicInfoTab = false;
    },
    handleCloseViewPModal () {
      this.loadPolicies();
      this.viewPolicyModal = false;
      this.selectedPolicy = []
    },
    updateValidation(validation) {
      // Event emmited from validation component, update active validation
      this.riskScenario.validation = validation
    },
    showDescriptionInputFunction() {
      this.showDescriptionInput = true;
      this.riskScenarioEditDescription = this.riskScenario.description;
    },
    formDescriptionSubmit() {
      let self = this;
      piincHttp.put('risk-scenarios/' + this.$route.params.slug, {
        description: this.riskScenarioEditDescription
      }).then(function(response) {
        self.riskScenario = response.data;
        self.showDescriptionInput = false;
        self.$toastr('success', self.$t('risk_scenarios.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    cancelDescriptionForm() {
      this.showDescriptionInput = false;
      this.riskScenarioEditDescription = this.riskScenario.description;
    },
    openViewPolicyModal(object) {
      this.selectedPolicy = object
      this.viewPolicyModal = true
    },
    reloadContent(object) {
      if (this.tabIndex == 1) {
        this.tabIndex = 0
      }
      // If slug is not changed, just reload content
      if (object.slug == this.riskScenario.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.breadcrumbItems.pop();
        this.loadContent(true);
      } else {
        this.$store.commit('storeUpdateTasks', true);
        // If slug changed, reload state with new param
        this.$router.push({ name: 'risk_scenarios.view', params: { slug: object.slug }})
      }
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-riskScenario'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-riskScenario'].hide()
    },
    confirmDelete(object) {
      let self = this;
      piincHttp.delete('risk-scenarios/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('risk_scenarios.delete.success'))
      }, function() {});
    },
    handleLink() {
      let self = this;
      let formData = new FormData();

      if (!this.inputLink.startsWith('http')) {
        this.inputLink = 'http://' + this.inputLink
      }

      formData.append("url", this.inputLink);
      formData.append("model_type", this.riskScenario.who_am_i);
      formData.append("model_id", this.riskScenario.id);

      piincHttp.post('links', formData).then(function() {
        // Remove last item in breadcrumbs, because it will be added again on load content
        self.breadcrumbItems.pop();
        self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
        self.loadContent()
        self.inputLink = ''
        self.showInput = false
      }, function() {})
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    openDeleteAttachmentModal(object) {
      this.selectedFile = object
      this.showDeleteAttachmentModal = true
    },
    closeDeleteAttachmentModal () {
      this.selectedFile = {}
      this.showDeleteAttachmentModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    openDeleteLinkModal(object) {
      this.selectedLink = object
      this.showDeleteLinkModal = true
    },
    closeDeleteLinkModal () {
      this.selectedLink = {}
      this.showDeleteLinkModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    onFileChange(file) {
      let files = file.target.files;
      let self = this;
      let formData = new FormData();
      let sizeError = false;

      for (var i = 0; i < files.length; i++) {
        // Equal to 10 MB
        if (files[i].size > 10485760) {
          sizeError = true;
        }
        formData.append('document[]', files[i]);
      }

      formData.append("name", "documents");
      formData.append("model_type", this.riskScenario.who_am_i);
      formData.append("model_id", this.riskScenario.id);

      if (sizeError) {
        this.error = this.$t('system.file_size')
      } else {
        piincHttp.post('documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function() {
          // Remove last item in breadcrumbs, because it will be added again on load content
          self.breadcrumbItems.pop();
          self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
          self.error = ''
          self.loadContent()
        }, function(error) {
          self.error = error.data.message;
          if (error.data.errors['document.0']) {
            self.error += ' ' + error.data.errors['document.0'][0]
          }
        })
      }
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Risk scenario' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Risk scenario',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    updateActivity() {
      this.loadContent();
    }
  },
  created () {
    let self = this;
    if (this.$route.params.tabIndex && this.$route.params.validation) {
      setTimeout(function(){
        self.tabIndex = self.$route.params.tabIndex
      }, 500);
      setTimeout(function(){
        var element = document.getElementsByClassName(self.$route.params.validation);
        element[0].scrollIntoView(true);
        window.scrollBy(0, -100);
        element[0].classList.add("highlight-validation");
      }, 2000);
    }
    if (this.getClient && this.getClient.slug) {
      this.loadContent(true);
    }
    this.loadGuides('risk_update_basic_info');
    this.loadGuides('risk_update_description');
  }
}
</script>
