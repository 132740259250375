<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <b-list-group>
            <b-list-group-item
                v-for="activity in items"
                :key="activity.id"
                class="border-left-0 border-right-0 border-top border-bottom-0 pt-4 pb-4"
            >

              <ProcessActivity
                  :activity="activity"
                  :changes="true"
                  v-if="activity.subject_type === 'App\\Process\\Models\\Process'"
              />
              <ProcessPolicyActivity
                  :activity="activity"
                  :changes="true"
                  v-if="activity.subject_type === 'App\\Process\\Models\\ProcessPolicy'"
              />
              <ProcessLegalBasisActivity
                  :activity="activity"
                  :changes="true"
                  v-if="activity.subject_type === 'App\\Process\\Models\\ProcessLegalBasis'"
              />
              <ProcessInformationObligationActivity
                  :activity="activity"
                  :changes="true"
                  v-if="activity.subject_type === 'App\\Process\\Models\\ProcessInformationObligation'"
              />
              <ProcessValidationActivity
                  :changes="true"
                  :activity="activity"
                  v-if="activity.subject_type === 'App\\Validation\\Models\\Validation'"
              />

              <ProcessRiskScenarioActivity
                      :activity="activity"
                      :changes="true"
                      v-if="activity.subject_type === 'App\\Process\\Models\\ProcessRiskScenario'"
              />

            </b-list-group-item>

          </b-list-group>
          <hr class="mt-0"/>
        </div>
        <InfiniteLoading @infinite="loadingHandler">
          <template slot="no-results">
            <span>
              {{ $t('system.no_data') }}
            </span>
          </template>
        </InfiniteLoading>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import ProcessActivity from '@/components/activity/process/Process.vue';
  import ProcessPolicyActivity from '@/components/activity/process/ProcessPolicy.vue';
  import ProcessLegalBasisActivity from '@/components/activity/process/ProcessLegalBasis.vue';
  import ProcessInformationObligationActivity from '@/components/activity/process/ProcessInformationObligation.vue';
  import ProcessValidationActivity from '@/components/activity/process/ProcessValidation.vue';
  import ProcessRiskScenarioActivity from '@/components/activity/process/ProcessRiskScenario.vue';

  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'ProcessHistory',
    data: function() {
      return {
        process: {},
        items: [],
        currentPage: 1,
        totalRows: 0,
        page: 1
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      ProcessActivity,
      ProcessPolicyActivity,
      ProcessLegalBasisActivity,
      ProcessValidationActivity,
      ProcessInformationObligationActivity,
      InfiniteLoading,
      ProcessRiskScenarioActivity
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      loadingHandler($state) {
        let self = this
        piincHttp.get('activity-log', { params:
          {
            log: this.process.who_am_i + '::' + this.process.id,
            page: this.page,
            client: self.getClient.slug
          }
        }).then(({ data }) => {
          if (data.items.length) {
            self.page += 1;
            self.items.push(...data.items);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
      }
    },
    created () {
      this.process = this.object;
    }
  }
</script>
