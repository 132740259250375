<template>
  <div>
    <!-- Retention rules table -->
    <div class="card pagination-margin"
         v-if="isGranted([permissions.RISK_SCENARIO_THREAT_VIEW, permissions.RISK_SCENARIO_THREAT_VIEW_OWN], activity)"
         qid="threat-section"
         id="update_threat"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="float-left">
              <h5 class="mb-2" qid="threat-table-title">
                {{ $t('risk_scenarios.tab.threats') }}
              </h5>
              <span class="cursor-pointer task-label-open mr-2"
                    v-if="getTask('risk_update_threat')"
                    @click="openToolbar(getTask('update_threat'))"
              >
                <font-awesome-icon icon="info-circle" class="text-primary"/>
                {{ $t('tasks.open_label') }}
              </span>
              <span v-if="getGuide('risk_update_threat')">
                <GuideButton :guide="getGuide('risk_update_threat')"></GuideButton>
              </span>
            </div>

          </div>
          <div class="col-sm-6 text-right">

            <button class="btn btn-success float-right"
                    @click="showCreateRRModal()"
                    qid="retention-rule-create-button"
                    v-if="isGranted([permissions.RISK_SCENARIO_THREAT_CREATE, permissions.RISK_SCENARIO_THREAT_CREATE_OWN], activity) && (!getClient.parent || (getClient.parent && activity.partition))"
            >
              <font-awesome-icon icon="plus"/> {{ $t('risk_scenarios.threat.add_new') }}
            </button>
            <b-form-input
                type="text"
                v-model="keywordValue"
                class="float-right mw-300 mr-3"
                qid="search-s-input"
                v-on:input="debounceInput"
                :placeholder="$t('risk_scenarios.threat.search_placeholder')"
            ></b-form-input>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('name')"
                qid="sort-s-name"
            >
              {{ $t('risk_scenarios.threat.table.name') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
              </span>
            </th>
            <th scope="col">
              {{ $t('risk_scenarios.threat.table.actors') }}
            </th>
            <th scope="col"
                class="cursor-pointer"
                @click="selectSort('updated_at')"
                qid="sort-s-name"
            >
              {{ $t('system.last_updated') }}
              <span class="ml-2">
                <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
              </span>
            </th>
            <th width="130"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in rrTableItems">
              <td>
                <button v-if="object.threat && object.threat.name"
                        @click="openViewThreatModal(object.threat)"
                        class="btn btn-link btn-sm text-left"
                >
                    {{ object.threat.name }}
                </button>
              </td>
              <td>
                <span v-if="object.threat && object.threat.actors && object.threat.actors.length" class="text-normal">
                  <span class="mr-1" v-for="actor in object.threat.actors" qid="p-tags-list">
                    <span class="mb-1">
                      {{actor.name}}
                    </span>
                  </span>
                </span>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
              <td class="text-right">
                <button v-if="object.threat && object.threat.name"
                        @click="openViewThreatModal(object.threat)"
                        class="btn btn-view"
                >
                  <font-awesome-icon icon="eye"/>
                </button>
                <button class="btn btn-delete"
                      qid="delete-threat-modal"
                      @click="showRRConfirmDeleteModal(object)"
                      v-if="isGranted([permissions.RISK_SCENARIO_THREAT_DELETE, permissions.RISK_SCENARIO_THREAT_DELETE_OWN], activity) && (!getClient.parent || (getClient.parent && activity.partition))"
                >
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </td>
            </tr>
            <tr v-if="!rrLoading && !rrTableItems.length" qid="no-results-row-rr" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                  v-model="perPage"
                  :options="perPageOptions"
                  track-by="value"
                  label="value"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-org-form-organization-level"
                  @select="perPageChanged"
                  :placeholder="selectedPerPage"
                  @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>


    <!-- Delete threat modal template -->
    <b-modal ref="delete-threat" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-threat-title">
          {{ $t('risk_scenarios.threat.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('risk_scenarios.threat.delete.description') }}
        <span v-if="itemToDelete.threat">{{ itemToDelete.threat.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('risk_scenarios.threat.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideRRConfirmDeleteModal"
                  qid="delete-threat-cancel-button"
        >
          {{ $t('risk_scenarios.threat.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteRR(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-threat-submit-button"
        >
          {{ $t('risk_scenarios.threat.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new threat -->
    <b-modal ref="add-threat" hide-footer @hide="hideCreateRRModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{ $t('risk_scenarios.threat.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="acFormSubmit" qid="create-ac-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-ac-pg-label">
              {{ $t('risk_scenarios.threat.create.rr_label') }}
            </label>
            <multiselect
                v-model="selectedRR"
                :options="threatItems"
                :placeholder="$t('risk_scenarios.threat.create.rr_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadThreats"
                track-by="slug"
                qid="create-rr-form-rr"
            ></multiselect>
          </b-form-group>
          <div v-if="selectedRR && selectedRR.description"
               class="text-center mb-3 mt-4"
          >
            <span class="cursor-pointer">
              <span class="mr-2"
                    @click="showRRDescription = !showRRDescription"
                    qid="expand-rr-description"
              >
                {{$t('risk_scenarios.threat.create.rr_description_expand')}}
              </span>
              <font-awesome-icon icon="chevron-down" v-if="!showRRDescription"/>
              <font-awesome-icon icon="chevron-up" v-if="showRRDescription"/>
            </span>
          </div>
          <div v-if="selectedRR && selectedRR.description && showRRDescription" class="mb-4 mt-2">
            <span class="text-formatted">
              {{ selectedRR.description }}
            </span>
          </div>
          <br>
          <div v-if="selectedRR && selectedRR.actors && selectedRR.actors.length">
            <label class="mt-3">
              {{ $t('maintenance.threats.table.actors') }}
            </label>
            <br>
            <span class="mr-1" v-for="actor in selectedRR.actors" qid="p-tags-list">
              <span class="badge badge-info mb-1">{{actor.name}}</span>
            </span>
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreateRRModal"
                    qid="create-rr-cancel-button"
          >
            {{ $t('risk_scenarios.threat.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-rr-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('risk_scenarios.threat.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <RiskScenarioThreatViewModal
            :object="selectedItem"
            :showModal="viewRiskModal"
            @closeViewModal="handleCloseViewThreatModal"
            v-if="selectedItem"
    ></RiskScenarioThreatViewModal>
  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import ValidationList from '@/views/Validations/ValidationList.vue';
  import ValidationForm from '@/views/Validations/ValidationForm.vue';
  import RiskScenarioThreatViewModal from '@/views/RiskScenarios/RiskScenarioThreatViewModal.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'ThreatsTable',
    data: function() {
      return {
        acForm: {
          name: ''
        },
        error: '',
        loadingItems: false,
        selectedRR: [],
        viewRRModal: false,
        editRRModal: false,
        selectedRRSort: '',
        selectedRRDirection: '',
        acValidationShow: false,
        rrTableItems: [],
        rrLoading: true,
        threatItems: [],
        updateValidationList: false,
        itemToDelete: {},
        itemObject: {},
        reCertificationOptions: [],
        provisioningOptions: [],
        selectedRC: [],
        selectedP: [],
        validationOptions: [],
        validation: undefined,
        disabledValidationOptions: [],
        showRRDescription: false,
        buttonDisabled: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',
        selectedItem: {},
        viewRiskModal: false,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      tasks: {
        type: Array
      }
    },
    components: {
      Avatar,
      ValidationList,
      ValidationForm,
      RiskScenarioThreatViewModal,
      GuideButton
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadThreats();
      },
      object: function (value) {
        this.activity = value
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadRiskScenarioThreats()
      }, 400),
      setValidation(object) {
        this.validation = object
        this.updateValidationList = false;
      },
      openViewThreatModal(object) {
        this.selectedItem = object
        this.viewRiskModal = true
      },
      handleCloseViewThreatModal () {
        this.selectedItem = []
        this.viewRiskModal = false;
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadRiskScenarioThreats();
      },
      getTask(code) {
        let existingTask = _.find(this.tasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Risk scenario' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Risk scenario',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      showCreateRRModal() {
        this.$refs['add-threat'].show()
      },
      hideCreateRRModal() {
        this.error = ''
        this.selectedRR = []
        this.loadContent()
        this.$refs['add-threat'].hide()
      },
      showRRConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-threat'].show()
      },
      hideRRConfirmDeleteModal() {
        this.itemToDelete = {}
        this.loadContent()
        this.$refs['delete-threat'].hide()
      },
      handleValidationModalClose() {
        this.updateValidationList = true
        this.loadContent()
      },
      confirmDeleteRR(object) {
        let self = this;
        piincHttp.delete('risk-scenarios/' + this.$route.params.slug + '/threats/' + object.id).then(function() {
          self.hideRRConfirmDeleteModal();
          self.loadRiskScenarioThreats();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('risk_scenarios.threat.delete.success'))
        }, function() {});
      },
      acFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedRR && this.selectedRR.id) {
          formData.append("threat_id", this.selectedRR.id);
        }

        piincHttp.post('/risk-scenarios/' + this.$route.params.slug + '/threats', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.$toastr('success', self.$t('risk_scenarios.threat.create.success'))
          self.hideCreateRRModal();
          self.loadRiskScenarioThreats();
          self.$emit('updated');
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.threat_id) {
              self.error += ' ' + error.data.errors.threat_id[0]
            }
          }
        })
      },
      loadContent() {
        let self = this;
        piincHttp.get('risk-scenarios/' + this.$route.params.slug, { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.activity = response.data;
        }, function() {});
      },
      loadRiskScenarioThreats(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;

        piincHttp.get('risk-scenarios/' + this.$route.params.slug + '/threats', { params:
            {
              page: pageNumber,
              per_page: perPageNumber,
              sort: this.selectedSort !== '' ? this.selectedSort : undefined,
              order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
              keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
              client: this.getClient.slug
            }
        }).then(function(response) {

          self.rrLoading = false;
          self.rrTableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;
        }, function() {});
      },
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadRiskScenarioThreats(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadRiskScenarioThreats(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadRiskScenarioThreats(1, self.selectedPerPage)

      },
      loadThreats(query) {
        let self = this;
        piincHttp.get('threats', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.threatItems = response.data.items
          if (self.threatItems && !self.threatItems.length && !query) {
            self.threatItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
          if (self.threatItems && self.threatItems.length) {
            self.validationOptions = _.cloneDeep(self.threatItems[0].validationOptions)
          }
        }, function() {});
      },
    },
    created () {
      this.activity = this.object
      if (this.getClient && this.getClient.slug) {
        this.loadThreats();
      }
      // Table items
      this.loadRiskScenarioThreats();
      this.loadGuides('risk_update_threat')
    }
  }
</script>
